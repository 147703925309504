import React, { useState, useEffect } from "react";
import axios from "axios";

const App = () => {
	const [posts, setPosts] = useState([]);
	const [tags, setTags] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);
	const [activeTag, setActiveTag] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMorePosts, setHasMorePosts] = useState(true);
	const postsPerPage = 5;
	const [fadeOut, setFadeOut] = useState(false);

	//for local env: const WORDPRESS_API_BASE = "YOUR_WORDPRESS_SITE_URL/wp-json/wp/v2";
	const WORDPRESS_API_BASE = `${window.wordpressData.siteUrl}/wp-json/wp/v2`;

	const fetchPosts = (page = 1) => {
		axios.get(`${WORDPRESS_API_BASE}/posts?_embed&per_page=${postsPerPage}&page=${page}`).then((res) => {
			if(res.data.length < postsPerPage){
				setHasMorePosts(false);
			}
			setPosts((prevPosts) => [
				...prevPosts.filter((prevPost) => !res.data.some((newPost) => newPost.id === prevPost.id)),
				...res.data,
			]);

			setFilteredPosts((prevPosts) => [
				...prevPosts.filter((prevPost) => !res.data.some((newPost) => newPost.id === prevPost.id)),
				...res.data,
			]);
		}).catch((err) => {
			if(err.response && err.response.status === 400){
				setHasMorePosts(false);
			}else{
				console.error(err);
			}
		});
	};

	const fetchTags = () => {
		axios.get(`${WORDPRESS_API_BASE}/tags`).then((res) => {
			const tagList = res.data;
			const postTagIds = new Set(posts.flatMap((post) => post.tags));
			const tagsWithPosts = tagList.filter((tag) => postTagIds.has(tag.id));
			setTags(tagsWithPosts);
		});
	};

	useEffect(() => {
		fetchPosts(currentPage);
	}, [currentPage]);

	useEffect(() => {
		if(posts.length > 0){
			fetchTags();
		}
	}, [posts]);

	const filterByTag = (tagId) => {
		setFadeOut(true); // Trigger fade-out animation
		setTimeout(() => {
			setActiveTag(tagId);
			if(tagId){
				const filtered = posts.filter((post) => post.tags && post.tags.includes(tagId));
				setFilteredPosts(filtered);
			}else{
				setFilteredPosts(posts);
			}
			setFadeOut(false); // Trigger fade-in animation
		}, 300); // Match duration with CSS transition
	};

	const loadMorePosts = () => setCurrentPage((prevPage) => prevPage + 1);

	return (
		<div className="feed">
			{tags.length > 0 && (
				<div className="tag-buttons">
					<button onClick={() => filterByTag(null)} className={`${!activeTag ? "active" : ""}`}>All</button>
					{tags.map((tag) => (
						<button key={tag.id} onClick={() => filterByTag(tag.id)} className={`${activeTag === tag.id ? "active" : ""}`}>{tag.name}</button>
					))}
				</div>
			)}

			<div className={`posts ${fadeOut ? "fade-out" : "fade-in"}`}>
				{filteredPosts.map((post) => {
					const featuredMedia = post._embedded && post._embedded["wp:featuredmedia"] ? post._embedded["wp:featuredmedia"][0].source_url : null;
					const videoUrl = post.meta?.youtube_video_url; // Assuming this is the custom field name
					//const postDate = new Date(post.date).toLocaleDateString();
					const postDate = new Date(post.date).toLocaleDateString("en-US", {
						year: "numeric",
						month: "long",
						day: "2-digit",
					});


					return(
						<div key={post.id} className="post">
							<div className="post-media">
								{videoUrl ? (
									<iframe width="100%" height="200" src={`https://www.youtube.com/embed/${new URL(videoUrl).searchParams.get("v")}`} frameBorder="0" allowFullScreen title={post.title.rendered}></iframe>
								) : (
									featuredMedia && (
										<img src={featuredMedia} alt={post.title.rendered} />
									)
								)}
							</div>

							<div className="post-content">
								<p className="!mb-0">Published on {postDate}</p>
								<h2><a href={post.link} target="_blank" rel="noopener noreferrer">{post.title.rendered}</a></h2>
								<div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
							</div>
						</div>
					);
				})}
			</div>

			{hasMorePosts && (
				<div className="load-more">
					<button onClick={loadMorePosts}>Load More</button>
				</div>
			)}
		</div>
	);
};

export default App;